import {
  blackColor, cardTitle, container,





  description, grayColor,




  hexToRgb, primaryColor,








  section,
  textCenter, title, whiteColor
} from "assets/jss/material-kit-pro-react.jsx"
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx"


const contactFormStyle = (theme) => ({
  ...modalStyle(theme),
  description,
  inputItem: {
    display: "flex",
  },
  modalMargin: {
    margin: "1000px auto 130px auto !important",
  },
  inputIconItem: {
    display: "flex",
    justifyContent: "flex-end",
  },
  inputAlign: {
    display: "flex",
    alignItems: "center",
    marginBottom: "10px",
  },
  formGroupItem: {
    flexDirection: "column",
    marginBottom: "30px",
  },
  formGroupTitle: {
    ...description,
    marginTop: "36px",
    marginBottom: "0px",
  },
  inputIcon: {
    margin: "36px 5px auto auto",
  },
  customTextField: {
    color: "green",
  },
  subtitle: {
    ...description,
    textAlign: "center",
    margin: "0 auto 30px auto",
  },
  title: {
    ...title,
    textAlign: "center",
  },
  section: {
    ...section,
    padding: "70px 0px 0px",
  },
  formItem: {
    display: "flex",
    justifyContent: "center",
  },
  container: {
    ...container,
    zIndex: "2",
    position: "relative",
    paddingTop: "0",
    justifyContent: "center",
  },
  bottomFormContainer: {
    margin: "12px 0px 0px 0px",
    [theme.breakpoints.down("sm")]: {
      margin: "12px 0px 70px 0px",
    },
  },
  pageHeader: {
    minHeight: "100vh",
    height: "auto",
    display: "inherit",
    position: "relative",
    margin: "0",
    padding: "0",
    border: "0",
    alignItems: "center",
    "&:before": {
      background: "rgba(" + hexToRgb(blackColor) + ", 0.5)",
    },
    "&:after": {
      background:
        "linear-gradient(60deg,rgba(" +
        hexToRgb(primaryColor[4]) +
        ",.56),rgba(" +
        hexToRgb(primaryColor[5]) +
        ",.95))",
    },
    "&:before,&:after": {
      position: "absolute",
      zIndex: "1",
      width: "100%",
      height: "100%",
      display: "block",
      left: "0",
      top: "0",
      content: '""',
    },
  },
  modalBody: {
    ...textCenter,
  },
  modalCardCategory: {
    textTransform: "uppercase",
    color: "#377358",
    display: "flex",
    alignItems: "center",
    margin: "0",
    "& path": {
      color: "#377358 !important",
    },
  },
  modalFooterBordered: {
    padding: "24px 0px 0px 0px",
    margin: "40px -39px -10px -39px",
    justifyContent: "center",
    borderTop: "1px solid #ccc",
  },
  cardSignup: {
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(" +
      hexToRgb(blackColor) +
      ", 0.14), 0 6px 30px 5px rgba(" +
      hexToRgb(blackColor) +
      ", 0.12), 0 8px 10px -5px rgba(" +
      hexToRgb(blackColor) +
      ", 0.2);",
    marginBottom: "100px",
    padding: "40px 0px",
  },
  cardIcon: {
    fontSize: "1rem",
    marginRight: "3px",
    "& path": {
      color: "#e4240ecc",
    },
  },
  cardTitle: {
    ...cardTitle,
    textDecoration: "none",
    textAlign: "center !important",
    marginBottom: "0.75rem",
  },
  socials: {
    marginTop: "0",
    position: "absolute",
    width: "100%",
    transform: "none",
    left: "0",
    top: "0",
    height: "100%",
    lineHeight: "41px",
    fontSize: "20px",
  },
  textCenter: {
    textAlign: "center",
  },
  inputAdornment: {
    marginRight: "18px",
    position: "relative",
  },
  inputAdornmentIcon: {
    color: grayColor[13],
  },
  form: {
    margin: "0",
  },
  infoArea: {
    padding: "0px 0px 20px !important",
  },
  block: {
    color: "inherit",
    padding: "0.9375rem",
    fontWeight: "500",
    fontSize: "12px",
    textTransform: "uppercase",
    borderRadius: "3px",
    textDecoration: "none",
    position: "relative",
    display: "block",
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0px",
    width: "auto",
  },
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0",
  },
  left: {
    float: "left!important",
    display: "block",
    "&,& *,& *:hover,& *:focus": {
      color: whiteColor + "  !important",
    },
  },
  right: {
    padding: "15px 0",
    margin: "0",
    float: "right",
    "&,& *,& *:hover,& *:focus": {
      color: whiteColor + "  !important",
    },
  },
  icon: {
    width: "18px",
    height: "18px",
    top: "3px",
    position: "relative",
  },
  chilimaniaContactInfo: {
    marginTop: "3rem",
  },
  eventLogo: {
    width: "225px",
    height: "150px",
    margin: "0 auto",
  },
})

export default contactFormStyle
