import {
  faEnvelopeOpenText,
  faMailbox,
  faPhone,
} from "@fortawesome/pro-duotone-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import Slide from "@material-ui/core/Slide"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import Close from "@material-ui/icons/Close"
import Email from "@material-ui/icons/Email"
import Label from "@material-ui/icons/Label"
// @material-ui/icons
import Person from "@material-ui/icons/Person"
import contactFormStyle from "assets/jss/material-kit-pro-react/components/contactFormStyle.jsx"
// @material-ui/icons
import axios from "axios"
import Button from "components/CustomButtons/Button.jsx"
// core components
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import { Formik } from "formik"
// gatsby libraries
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
// nodejs library to set properties for components
import PropTypes from "prop-types"
import React from "react"
import * as Yup from "yup"

// const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const contactFormEndpoint =
  "https://cznh3fqbab.execute-api.us-west-2.amazonaws.com/prod/contact-us"

// page modal functions
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />
})
Transition.displayName = "Transition"
const useStyles = makeStyles(contactFormStyle)

export default function ContactForm(props) {
  const classes = useStyles()
  const { buttonColor } = props
  const [open, setOpen] = React.useState(false)
  const [scroll, setScroll] = React.useState("body")
  const [isSubmissionCompleted, setSubmissionCompleted] = React.useState(false)

  const { chilimaniaLogo } = useStaticQuery(graphql`
    query getContactFormImages {
      chilimaniaLogo: file(
        relativePath: { eq: "logos/chilimania-logo-transparent-white.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)

  function handleClose() {
    setOpen(false)
  }

  function handleClickOpen() {
    setSubmissionCompleted(false)
    setOpen(true)
    setScroll("body")
  }

  return (
    <>
      <Button
        className={classes.navLink}
        onClick={handleClickOpen}
        color={buttonColor ? buttonColor : "transparent"}
      >
        <Email />
        Contact
      </Button>

      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modal + " " + classes.modalLarge,
        }}
        TransitionComponent={Transition}
        keepMounted
        open={open}
        scroll={scroll}
        onClose={handleClose}
        aria-labelledby="contact-modal-slide-title"
        aria-describedby="contact-modal-slide-description"
      >
        {!isSubmissionCompleted && (
          <>
            <DialogTitle
              id="contact-modal-slide-title"
              disableTypography
              className={classes.modalHeaderBordered}
            >
              <Button
                simple
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
                onClick={handleClose}
              >
                <Close className={classes.modalClose} />
              </Button>
              <h6 className={classes.modalCardCategory}>
                <Email className={classes.cardIcon} />
                Contact
              </h6>
            </DialogTitle>
            <DialogContent
              id="contact-modal-slide-description"
              className={classes.modalBody}
            >
              <h4 className={classes.title}>Contact Chilimania</h4>
              <p className={classes.description}>
                We would love to hear from you! Please use the form below to
                select the topic of your question or comment.
              </p>
              <Formik
                initialValues={{
                  name: "",
                  email: "",
                  subject: "",
                  comments: "",
                }}
                onSubmit={(values, { setSubmitting }) => {
                  setSubmitting(true)
                  axios.post(contactFormEndpoint, values).then((resp) => {
                    setSubmissionCompleted(true)
                  })
                }}
                validationSchema={Yup.object().shape({
                  name: Yup.string().required("Required"),
                  email: Yup.string().email().required("Required"),
                  subject: Yup.string().required("Required"),
                  comments: Yup.string().required("Required"),
                })}
              >
                {(props) => {
                  const {
                    values,
                    touched,
                    errors,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  } = props
                  return (
                    <form onSubmit={handleSubmit}>
                      <div className={classes.container}>
                        <GridContainer justifyContent="center">
                          <GridItem
                            md={5}
                            sm={12}
                            className={classes.inputItem}
                          >
                            <Person className={classes.inputIcon} />
                            <TextField
                              fullWidth
                              error={errors.name && touched.name}
                              label="Name"
                              name="name"
                              className={classes.textField}
                              value={values.name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              helperText={
                                errors.name && touched.name && errors.name
                              }
                              margin="normal"
                            />
                          </GridItem>
                          <GridItem
                            md={5}
                            sm={12}
                            className={classes.inputItem}
                          >
                            <Email className={classes.inputIcon} />
                            <TextField
                              fullWidth
                              error={errors.email && touched.email}
                              label="Email"
                              name="email"
                              className={classes.customTextField}
                              value={values.email}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              helperText={
                                errors.email && touched.email && errors.email
                              }
                              margin="normal"
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer justifyContent="center">
                          <GridItem
                            md={10}
                            sm={12}
                            className={classes.inputItem}
                          >
                            <Email className={classes.inputIcon} />
                            <TextField
                              fullWidth
                              error={errors.subject && touched.subject}
                              label="Subject"
                              name="subject"
                              className={classes.customTextField}
                              value={values.subject}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              helperText={
                                errors.subject &&
                                touched.subject &&
                                errors.subject
                              }
                              margin="normal"
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer justifyContent="center">
                          <GridItem
                            md={10}
                            xs={12}
                            className={classes.inputItem}
                          >
                            <Label className={classes.inputIcon} />
                            <TextField
                              fullWidth
                              error={errors.comments && touched.comments}
                              label="Comments"
                              name="comments"
                              className={classes.customTextField}
                              value={values.comments}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              helperText={
                                errors.comments &&
                                touched.comments &&
                                errors.comments
                              }
                              margin="normal"
                              multiline
                              minRows={4}
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer justifyContent="center">
                          <GridItem
                            md={8}
                            sm={12}
                            className={classes.chilimaniaContactInfo}
                          >
                            <GatsbyImage
                              image={
                                chilimaniaLogo.childImageSharp.gatsbyImageData
                              }
                              alt="Chilimania Logo"
                              className={classes.eventLogo}
                              objectFit={"contain"}
                            />
                            <p className={classes.description}>
                              <b>Contact Information</b> <br />
                            </p>
                            <p className={classes.description}>
                              <FontAwesomeIcon icon={faPhone} /> Phone:
                              <br />
                              <a href="tel:6085617566">(608) 561-7566</a>
                            </p>
                            <p className={classes.description}>
                              <FontAwesomeIcon icon={faEnvelopeOpenText} />{" "}
                              Email:
                              <br />
                              <a href="mailto:contact@chilimania.com">
                                contact@chilimania.com
                              </a>
                            </p>
                            <p className={classes.description}>
                              <FontAwesomeIcon icon={faMailbox} /> Address:
                              <br />
                              1007 N Main St, Edgerton, WI 53534
                            </p>
                          </GridItem>
                        </GridContainer>
                        <DialogActions className={classes.modalFooterBordered}>
                          <Button
                            round
                            color="primary"
                            type="submit"
                            disabled={isSubmitting}
                          >
                            Submit
                          </Button>
                          {/* <DisplayFormikState {...props} /> */}
                        </DialogActions>
                      </div>
                    </form>
                  )
                }}
              </Formik>
            </DialogContent>
          </>
        )}
        {isSubmissionCompleted && (
          <>
            <DialogTitle id="form-dialog-title">Thank You!</DialogTitle>
            <DialogContent>
              <p className={classes.description}>
                <b>Your email has been sent</b>
                <br />
                We will get back to you as soon as possible.
              </p>
              <DialogActions>
                <Button
                  color="primary"
                  type="button"
                  className="outline"
                  onClick={handleClose}
                >
                  Return to Chilimania Website
                </Button>
                {/* <DisplayFormikState {...props} /> */}
              </DialogActions>
            </DialogContent>
          </>
        )}
      </Dialog>
    </>
  )
}

ContactForm.propTypes = {
  buttonColor: PropTypes.oneOf([
    "primary",
    "rose",
    "dark",
    "info",
    "success",
    "warning",
    "danger",
  ]),
}
